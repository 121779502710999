<template>
  
  <router-view />

</template>
<script >

</script >
<style>





</style>
