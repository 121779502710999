<template>

    
    <section class="bg-white dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div class=" max-w-screen-sm text-center lg:mb-16 mb-8">
                <h2 id="organisateurs"
                    class="mb-2  px-4 card-title flex cursive uppercase  tracking-tight text-gray-900 dark:text-white">
                    Nos Partenaires

                </h2>

                <div class=" px-6 bg-white rounded-full h-2.5 dark:bg-white">
  <div class="bg-red-600 h-1.5 " style="width: 15%"></div>
</div>


            </div>
            <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-12 md:space-y-0">

                <div>
                    <a href="https://be.brussels/fr/propos-de-la-region">
                        <div
                            class="flex justify-center  p-6 items-center mb-4 cursor-pointer w-full h-full bg-primary-100 dark:bg-primary-900">
                            <img class="max-w-ms mx-auto" src="../assets/brxcap.png" alt="image description" />
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://hub.brussels/fr/">
                        <div
                            class="flex justify-center  p-6 border-l items-center mb-4 cursor-pointer w-full h-full bg-primary-100 dark:bg-primary-900">
                            <img class="max-w-ms mx-auto" src="../assets/logo-brussel.png" alt="image description" />
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://www.awex.be/">
                        <div
                            class="flex justify-center items-center p-6 border-l cursor-pointer mb-4 w-full h-full bg-primary-100 dark:bg-primary-900">
                            <img class="max-w-ms mx-auto" src="../assets/logo-wallonie.png" alt="image description" />
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://welcome.flandersinvestmentandtrade.com/en">
                        <div
                            class="flex justify-center items-center border-l cursor-pointer p-6 mb-4 w-full h-full bg-primary-100 dark:bg-primary-900">
                            <img class="max-w-ms h-auto" src="../assets/logo-flanders.png" alt="image description" />
                        </div>
                    </a>
                </div>

            </div>
        </div>
        <div class="">
    <!-- <div class=" float-start " > 
        
        <div class="card" style="width: 18rem; border: none;">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <h6 class="card-subtitle mb-2 text-body-secondary">Card subtitle</h6>
    <p class="card-text">
        hub.brussel vise à promouvoir l'internationalisation de l'économie bruxelloise en assistant les entreprises bruxelloises sur les marchés étrangers</p>
  
  </div>
</div>
        </div> -->
<!-- <div class=" float-end" >
    <div class="card" style="width: 18rem; border: none;">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <h6 class="card-subtitle mb-2 text-body-secondary">Card subtitle</h6>
    <p class="card-text">
        Le service Economique et Commercial ne s'occupe pas de Visas</p>
  
  </div> -->
<!-- </div> -->
<!-- </div> -->
</div>
    </section>
    <section class="bg-gray-100 dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class=" max-w-screen-sm text-center lg:mb-16 mb-8">
          <h2 class="mb-2  px-4 card-title flex cursive uppercase  tracking-tight text-gray-900 dark:text-white">NOS SERVICES</h2>
          <div class="px-6 bg-gray-100 rounded-full h-2.5 dark:bg-white">
  <div class="bg-red-600 h-1.5 " style="width: 15%"></div>
</div>
          <!-- <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">We use an agile approach to test assumptions and connect with the needs of your audience early and often.</p> -->
      </div> 
      <div class="grid gap-8 lg:grid-cols-2 anim">
          <article class="p-6 bg-white rounded-lg   border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <!-- <div class="flex justify-between items-center mb-5 text-gray-500"> -->
                  <!-- <span class="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                      <svg class="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>
                      Tutorial
                  </span>
                  <span class="text-sm">14 days ago</span> -->
              <!-- </div> -->
              <!-- <h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"><a href="#">How to quickly deploy a static website</a></h2> -->
              <p class="mb-2 font-medium text-gray-900 dark:text-gray-900">  Le service économique et commercial Belge à Dakar est un service extérieur des gouvernements de Bruxelles-Capitale, de Flandre et de Wallonie. Il représente les trois agences régionales du commerce extérieur (Hub.Brussels, FIT et AWEX) et a pour juridiction le Sénégal, la Gambie, la Guinée, le Cap-Vert et la Guinée-Bissau. Le service économique et commercial apporte un soutien au développement international de toutes les entreprises du territoire Belge.</p>
              <!-- <div class="flex justify-between items-center"> -->
                  <!-- <div class="flex items-center space-x-4">
                      <img class="w-7 h-7 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png" alt="Jese Leos avatar" />
                      <span class="font-medium dark:text-white">
                          Jese Leos
                      </span>
                  </div> -->
                  <!-- <a href="#" class="inline-flex items-center font-medium text-primary-600 dark:text-primary-500 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a> -->
              <!-- </div> -->
          </article> 
          <article class="p-6 bg-white rounded-lg border anim1 border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <!-- <div class="flex justify-between items-center mb-5 text-gray-500">
                  <span class="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                      <svg class="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z" clip-rule="evenodd"></path><path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"></path></svg>
                      Article
                  </span>
                  <span class="text-sm">14 days ago</span>
              </div> -->
              <!-- <h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"><a href="#">Our first project with React</a></h2> -->
              <p class="mb-2 font-medium text-gray-900 dark:text-gray-900"> Le service Economique et Commercial ne s'occupe pas de Visas.</p>
              <p class="mb-2 font-medium text-gray-900 dark:text-gray-900">  Les informations reprises sur ce site proviennent de l'internet  (informations publiques). Si votre information n'est plus à jour nous pouvons la modifier ou la supprimer sur simple demande.</p>
              <div class="flex justify-between items-center">
                  <!-- <div class="flex items-center space-x-4">
                      <img class="w-7 h-7 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png" alt="Bonnie Green avatar" />
                      <span class="font-medium dark:text-white">
                          Bonnie Green
                      </span>
                  </div> -->
                  <!-- <a href="#" class="inline-flex items-center font-medium text-primary-600 dark:text-primary-500 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a> -->
              </div>
          </article>                  
      </div>  
  </div>
</section>
    
</template> 
<style scoped>
.anim{
    animation: mymove 5s infinite;
  animation-delay: 2s
}
@keyframes mymove {
  from {left: 0px;}
  to {left: 200px;}
}
</style>
