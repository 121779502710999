<template>
    <!-- <div class="mx-auto p-2">

        <img src="../assets/logo-brusselbis.png" class="float-start" alt="...">
    <img src="../assets/logo-ambassade.png" class=" float-end" alt="...">
    </div> -->
    <!-- <img class="h-auto w-full  mx-auto" src="../assets/bru-amb.png" alt="image description"> -->
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <a href="https://hub.brussels/fr/">

        <img src="../assets/logo-brusselbis.png" class="float-start" alt="...">
      </a>
      <a href="https://senegal.diplomatie.belgium.be/fr">

        <img src="../assets/logo-ambassade.png" class=" float-end" alt="...">
      </a>
    </div>
    <div class="carousel-item">
      <img src="../assets/logo-brusselbis.png" class="float-start" alt="...">
    <img src="../assets/logo-ambassade.png" class=" float-end" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../assets/logo-brusselbis.png" class="float-start" alt="...">
    <img src="../assets/logo-ambassade.png" class=" float-end" alt="...">
    </div>
  </div>
</div>
</template>
<style scoped>

</style>